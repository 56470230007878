<template>
  <b-container fluid >
    <b-row>
      <b-col md="12" class="heading_alignment">
        <iq-card style="padding-left:20px; padding-right:20px">
          <template v-slot:headerTitle>
            <h4 class="card-title">
              {{cvCardTitle}}
            </h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="interestCategoryAdd">
              {{cvbtnAddNew}}
            </b-button>
          </template>
          <template>
            <b-row class="mb-3">
              <b-col class="col-12 col-sm-12 col-md-6 col-lg-7 mb-3">
                <b-form-input
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>
              <!-- <b-col md="8">
                <b-button size="sm" variant="outline-secondary" class="pull-right mr-2">Excel</b-button>
                <b-button size="sm" variant="outline-secondary" class="pull-right mr-2">Csv</b-button>
                <b-button size="sm" variant="outline-secondary" class="pull-right mr-2">Coustomize</b-button>
              </b-col> -->
            </b-row>
            <b-row>
              <b-col md="12" v-if="interestCategoryObjList && interestCategoryObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="interestCategoryObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage">
                  <template v-slot:cell(created_on)="data">
                    {{getFormattedDateTime(data.item.created_on)}}
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="interestCategoryEdit('MODAL', data.item)" v-if="!data.item.editable">
                      <i class="ri-ball-pen-fill m-0"></i>
                    </b-button>
                    <b-button variant=" iq-bg-danger" @click="showInterestCategoryDeleteDialog(data.item)" size="sm">
                      <i class="ri-delete-bin-line m-0"></i>
                    </b-button>
                  </template>
                </b-table>
              </b-col>
              <b-col md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1">
              </b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModelInterestCategoryAdd"
      scrollable
      :title="cvAddModalHeader"
      size="xl">
      <InterestCategoryAdd :propOpenedInModal="true" @emitCloseInterestCategoryAddModal="closeInterestCategoryAddModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="interestCategoryAdd('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeInterestCategoryAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModelInterestCategoryEdit"
      scrollable
      :title="cvEditModalHeader"
      size="xl">
      <InterestCategoryEdit :propOpenedInModal="true" :propInterestCategoryObj="interestCategoryEditObj" @emitCloseInterestCategoryEditModal="closeInterestCategoryEditModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="interestCategoryEdit('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeInterestCategoryEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelInterestCategoryDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>
        {{cvDelMessage}}
      </p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelInterestCategoryDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="interestCategoryDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>
<script>
import { InterestCategorys } from "../../../FackApi/api/interestCategory.js"
import InterestCategoryAdd from "./InterestCategoryAdd"
import InterestCategoryEdit from "./InterestCategoryEdit"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse.js"
import { socialvue } from "../../../config/pluginInit.js"
import userPermission from "../../../Utils/user_permission.js"

export default {
  name: "InterestCategoryList",
  components: {
    InterestCategoryAdd,
    InterestCategoryEdit
  },
  data () {
    return {
      apiName: "interest_list",
      cvCardTitle: "Interest Categories",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit InterestCategory",
      cvAddModalHeader: "Add InterestCategory",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "InterestCategory List Response",
      showModelInterestCategoryAdd: false,
      showModelInterestCategoryEdit: false,
      showModelInterestCategoryDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left", sortable: true },

        { label: "Interest category", key: "interest_cat_name", class: "text-left", sortable: true },
        { label: "Created On ", key: "created_on", class: "text-left", sortable: true },
        { label: "Action ", key: "action", class: "text-left", sortable: false }
      ],
      interestCategoryObjList: null,
      interestCategoryEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      delObj: null
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
    socialvue.index()
    this.interestCategoryList()
  },
  methods: {
    /**
     * getFormattedDateTime
     */
    getFormattedDateTime (datetime) {
      try {
        return moment(datetime).format("DD-MM-YYYY hh:mm:ss")
      }
      catch (err) {
        console.error("Exception occurred at getFormattedDateTime() and Exception:", err.message)
      }
    },
    /**
     * interestCategoryList
     */
    async interestCategoryList () {
      try {
        let interestCategoryListResp = await InterestCategorys.interestCategoryList(this)
        if (interestCategoryListResp.resp_status) {
          this.interestCategoryObjList = interestCategoryListResp.resp_data.data
          this.totalRows = interestCategoryListResp.resp_data.count
        }
        else {
          this.toastMsg = interestCategoryListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at interestCategoryList() and Exception:", err.message)
      }
    },
    /**
     * interestCategoryAdd
     */
    interestCategoryAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/interestCategory_add")
        }
        else {
          this.showModelInterestCategoryAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at interestCategoryAdd() and Exception:", err.message)
      }
    },
    /**
     * interestCategoryEdit
     */
    interestCategoryEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("../interestCategory_edit/" + this.interestCategoryEditObj.interest_cat_id)
        }
        else {
          this.interestCategoryEditObj = item
          this.showModelInterestCategoryEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at interestCategoryEdit() and Exception:", err.message)
      }
    },
    /**
     * showInterestCategoryDeleteDialog
     */
    showInterestCategoryDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelInterestCategoryDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showInterestCategoryDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * interestCategoryDelete
     */
    async interestCategoryDelete () {
      try {
        let interestCategoryDelResp = await InterestCategorys.interestCategoryDelete(this, this.delObj.interest_cat_id)
        await ApiResponse.responseMessageDisplay(this, interestCategoryDelResp)
        if (interestCategoryDelResp && !interestCategoryDelResp) {
          this.showModelInterestCategoryDelete = false
          return false
        }
        let index = this.interestCategoryObjList.indexOf(this.delObj)
        this.interestCategoryObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelInterestCategoryDelete = false
      }
      catch (err) {
        console.error("Exception occurred at interestCategoryDelete() and Exception:", err.message)
      }
    },
    /**
     * closeInterestCategoryAddModal
     */
    closeInterestCategoryAddModal (interestCategoryAddData) {
      try {
        if (interestCategoryAddData) {
          if (this.interestCategoryObjList && this.interestCategoryObjList.length >= 1) {
            let interestCatObjLength = this.interestCategoryObjList.length
            let lastId = this.interestCategoryObjList[interestCatObjLength - 1]["id"]
            interestCategoryAddData.id = lastId + 1
          }
          else {
            this.interestCategoryObjList = []
            interestCategoryAddData.id = 11111
          }
          // interestCategoryAddData.interest_cat_id = `INTERESTCAT${interestCategoryAddData.id}`
          interestCategoryAddData.created_on = moment()
          this.interestCategoryObjList.unshift(interestCategoryAddData)
          this.totalRows = this.totalRows + 1
        }
        this.showModelInterestCategoryAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closeinterestCategoryAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeInterestCategoryEditModal
     */
    closeInterestCategoryEditModal () {
      try {
        this.showModelInterestCategoryEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeInterestCategoryEditModal() and Exception:", err.message)
      }
    }
  }
}
</script>
