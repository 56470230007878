<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">
              {{cvCardTitle}}
            </h4>
          </template>
          <template>
            <p v-show="!propOpenedInModal">
              {{cvCardSubHeader}}
            </p>
            <form action="#" v-if="vmInterestCategoryFormData && Object.keys(vmInterestCategoryFormData).length > 0">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label
                    for="validationinterest_cat_name">
                    {{cvInterestCatNameLabel}}</label>
                  <input
                    v-model="vmInterestCategoryFormData.interest_cat_name"
                    type="text"
                    class="form-control"
                    required/>
                </div>
              </div>
              <div class="form-group">
                <button type="button" class="btn btn-primary" @click="interestCategoryEdit()">
                  {{cvSubmitBtn}}
                </button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>

<script>
import { InterestCategorys } from "../../../FackApi/api/interestCategory"
import ApiResponse from "../../../Utils/apiResponse.js"
import { socialvue } from "../../../config/pluginInit.js"
import userPermission from "../../../Utils/user_permission.js"

export default {
  name: "InterestCategoryEdit",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propInterestCategoryObj: {
      type: Object,
      default: function () {
        return {
          "interest_cat_name": ""
        }
      }
    }
  },
  data () {
    return {
      apiName: "interestCategory_edit",
      cvCardTitle: "Edit Interest Category",
      cvCardSubHeader: "Edit Interest Category",
      cvSubmitBtn: "Edit",
      cvInterestCatNameLabel: "Interest category",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "InterestCategory",
      vmInterestCategoryFormData: {}
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
    this.interestCategoryView()
    socialvue.index()
  },
  methods: {
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmInterestCategoryFormData) {
          if (!this.vmInterestCategoryFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * interestCategoryView
     */
    async interestCategoryView () {
      try {
        if (this.propOpenedInModal) {
          this.vmInterestCategoryFormData = this.propInterestCategoryObj
        }
        else {
          let interestCategoryId = this.$route.params.interest_cat_id
          let interestCategoryViewResp = await InterestCategorys.interestCategoryView(this, interestCategoryId)
          if (interestCategoryViewResp && interestCategoryViewResp.resp_status) {
            this.vmInterestCategoryFormData = interestCategoryViewResp.resp_data.data
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at interestCategoryView() and Exception:", err.message)
      }
    },
    /**
     * interestCategoryEdit
     */
    async interestCategoryEdit () {
      try {
        // if (!this.validate()) {
        //   return false
        // }
        let interestCategoryAddResp = await InterestCategorys.interestCategoryEdit(this, this.vmInterestCategoryFormData)
        await ApiResponse.responseMessageDisplay(this, interestCategoryAddResp)
        if (interestCategoryAddResp && !interestCategoryAddResp.resp_status) {
          return false
        }
        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseInterestCategoryEditModal", this.vmInterestCategoryFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at interestCategoryEdit() and Exception:", err.message)
      }
    }
  }
}
</script>
